
import { mapMutations } from "vuex";

export default {
	methods: {
		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
		}),
		closeModals() {
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		}
	},
	computed: {
		nav() {
			return [
				{
					title: 'Автомобили с пробегом',
					link: '/cars'
				},
				{
					title: 'Автокредит',
					link: '/credit'
				},
				{
					title: 'Trade-In',
					link: '/exchange'
				},
				{
					title: 'О компании',
					link: '/about'
				},
			]
		},

		route(){
			return this.$route.path
		}
	},

}
