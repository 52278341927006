import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      openModal: "modal/modal-main/openModal"
    }),
    async callback() {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      let hours = new Date().getHours();

      let payload = {
        modal_data: {
          title: "Обратная связь",
          sub_title: (hours >= 21 || hours < 9)?"Мы работаем с 9:00 до 21:00.":" ",
          text: "Оставьте заявку, и мы свяжемся с Вами в рабочее время."
        },
        modal_component: "modal-timer",
        modal_title: "ЗАЯВКА НА АВТОКРЕДИТ",
        modal_sub_title: {}
      };
      this.openModal(payload);
    }
  }
};
